<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/ceibamóvil/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">VIETINBANK GLOBAL MONEY TRANSFER COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Hiểu chúng tôi</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Giới thiệu khoản vay</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/questions' }"
          class="Li3"
        >
          <router-link to="/questions" class="nav-link3">
            <span class="routeText3">Câu hỏi</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Thỏa thuận quyền riêng tư</span>
          </router-link>
        </li>
         <!-- <li
          :class="{
            'active-link': ['/questions', '/protocol'].includes($route.path),
          }"
          class="Li4"
        >
          <router-link to="/questions" class="nav-link4">
            <span class="routeText4">Vấn đề Thường Gặp</span>
          </router-link>
        </li> -->
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li5">
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Acuerdo de privacidad</span>
          </router-link>
        </li> -->
      </ul>
    </div>
    <div style="height: 130px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  position: sticky;  /* 将导航栏固定在页面上 */
  top: 0;  /* 位于页面顶部 */
  z-index: 1000;  /* 确保导航栏在其他元素上方 */
  height: 80px;
}
.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 80px;
  padding: 0 30px;
  background-color: #006557;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
}

.title {
  width: 580px;
  height: 24px;
  font-size: 18px;
  font-family: SF Pro-Black Italic;
  font-weight: 600;
  color: #ffffff;
  line-height: 23px;
  padding-left: 17px;
  /* text-transform: uppercase; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #ffffff;
  line-height: 29px;
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  padding: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 45px;
  padding-left: 4px;
  padding-right: 4px;
}
.Li1 {
  width: 130px;
}
.Li2 {
  width: 180px;
}
.Li3 {
  width: 100px;
}
.Li4 {
  width: 230px;
}
/* .Li5 {
  width: 250px;
} */
/* 这个如果给margin-right底部可能会出现边距的问题 */
/* .Li4 {
  width: 250px;
  
} */


.active-link {
  /* background: linear-gradient(45deg, #F59120, #F95C40); */
  background-color: #ffffff;
  /* border-color: #dae633; */
  border-radius: 20px;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;

  color: #006557;
}
</style>