<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <div class="leftImages">
          <img :src="importedImg3" :alt="altText" class="imgItem3" />
          <img
            :src="showNewImage4 ? AnswerImg4 : importedImg4"
            :alt="altText"
            class="imgItem4"
            @click="toggleNewImage(4)"
          />
          <img
            :src="showNewImage5 ? AnswerImg5 : importedImg5"
            :alt="altText"
            class="imgItem5"
            @click="toggleNewImage(5)"
          />
          <img
            :src="showNewImage6 ? AnswerImg6 : importedImg6"
            :alt="altText"
            class="imgItem6"
            @click="toggleNewImage(6)"
          />
        </div>
        <div class="rightImages">
          <img
            :src="showNewImage7 ? AnswerImg7 : importedImg7"
            :alt="altText"
            class="imgItem7"
            @click="toggleNewImage(7)"
          />
          <img
            :src="showNewImage8 ? AnswerImg8 : importedImg8"
            :alt="altText"
            class="imgItem8"
            @click="toggleNewImage(8)"
          />
          <img
            :src="showNewImage9 ? AnswerImg9 : importedImg9"
            :alt="altText"
            class="imgItem9"
            @click="toggleNewImage(9)"
          />
          <img
            :src="showNewImage10 ? AnswerImg10 : importedImg10"
            :alt="altText"
            class="imgItem10"
            @click="toggleNewImage(10)"
          />
        </div>
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg11" :alt="altText" class="imgItem11" />
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/hoabinhdong/components/img/1_1.png";
import importedImg2 from "@/page/hoabinhdong/components/img/1_2.png";
import importedImg3 from "@/page/hoabinhdong/components/img/1_3_1.png";
import importedImg4 from "@/page/hoabinhdong/components/img/1_3_2.png";
import importedImg5 from "@/page/hoabinhdong/components/img/1_3_3.png";
import importedImg6 from "@/page/hoabinhdong/components/img/1_3_4.png";
import importedImg7 from "@/page/hoabinhdong/components/img/1_3_5.png";
import importedImg8 from "@/page/hoabinhdong/components/img/1_3_6.png";
import importedImg9 from "@/page/hoabinhdong/components/img/1_3_7.png";
import importedImg10 from "@/page/hoabinhdong/components/img/1_3_8.png";
import importedImg11 from "@/page/hoabinhdong/components/img/1_4.png";
import AnswerImg4 from "@/page/hoabinhdong/components/img/1_3_22.png";
import AnswerImg5 from "@/page/hoabinhdong/components/img/1_3_33.png";
import AnswerImg6 from "@/page/hoabinhdong/components/img/1_3_44.png";
import AnswerImg7 from "@/page/hoabinhdong/components/img/1_3_55.png";
import AnswerImg8 from "@/page/hoabinhdong/components/img/1_3_66.png";
import AnswerImg9 from "@/page/hoabinhdong/components/img/1_3_77.png";
import AnswerImg10 from "@/page/hoabinhdong/components/img/1_3_88.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      importedImg10: importedImg10,
      importedImg11: importedImg11,
      altText: "图片",
      AnswerImg4: AnswerImg4,
      AnswerImg5: AnswerImg5,
      AnswerImg6: AnswerImg6,
      AnswerImg7: AnswerImg7,
      AnswerImg8: AnswerImg8,
      AnswerImg9: AnswerImg9,
      AnswerImg10: AnswerImg10,

      showNewImage2: false,
      showNewImage3: false,
      showNewImage4: false,
      showNewImage5: false,
      showNewImage6: false,
      showNewImage7: false,
      showNewImage8: false,
      showNewImage9: false,
      showNewImage10: false,
      showNewImage11: false,
      showNewImage12: false,
      showNewImage13: false,
      showNewImage14: false,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    toggleNewImage(imageIndex) {
      console.log(this.showNewImage3);

      if (imageIndex == 4) {
        this.showNewImage4 = !this.showNewImage4;
      }
      if (imageIndex == 5) {
        this.showNewImage5 = !this.showNewImage5;
      }
      if (imageIndex == 6) {
        this.showNewImage6 = !this.showNewImage6;
      }
      if (imageIndex == 7) {
        this.showNewImage7 = !this.showNewImage7;
      }
      if (imageIndex == 8) {
        this.showNewImage8 = !this.showNewImage8;
      }
      if (imageIndex == 9) {
        this.showNewImage9 = !this.showNewImage9;
      }
      if (imageIndex == 10) {
        this.showNewImage10 = !this.showNewImage10;
      }
      if (imageIndex == 11) {
        this.showNewImage11 = !this.showNewImage11;
      }
      if (imageIndex == 12) {
        this.showNewImage12 = !this.showNewImage12;
      }
      if (imageIndex == 13) {
        this.showNewImage13 = !this.showNewImage13;
      }
      if (imageIndex == 14) {
        this.showNewImage14 = !this.showNewImage14;
      }
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #f9fbfc;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1,
.img2,
.img3,
.img4,
.img5 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}


/* .imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem4 {
margin-bottom: 100px;
} */

.img3 {
  display: flex;
  justify-content: center;
  background-color: #FFFBE8;
}

.leftImages,
.rightImages {
  display: flex;
  flex-direction: column;
}
.leftImages{
  width: 45%;
}
.rightImages{
  width: 55%;
}
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10 {
  width: 100%;
  overflow: hidden;
}
.imgItem11{
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>
